<template>
  <div class="container">
    <div class="PaySuccess">
      <div class="PaySuccess-title"><h3>订单支付成功！</h3></div>

      <div class="PaySuccess-title"><span>订单详情</span></div>

      <div class="PaySuccess-details">
        <div class="details-top">
          <span style="flex: 1;">订单编号：{{ orderItems.length ? orderItems[0].orderSn : '' }}</span>
          <div class="details-num">共 {{ orderItems.length || '' }} 件商品</div>
        </div>
        <div class="details-center">
          <div v-for="(item, index) in orderItems" :key="index" class="details-center-items">
            <div class="img-box"><img v-lazy="item.skuPic" alt="goods"></div>
            <div class="details-info">
              <span>{{ index + 1 }}. {{ item.skuName }}</span>
            </div>
          </div>
        </div>
        <div class="details-bottom">
          <a-button class="db-mr20" @click="goOrderDetailsFun">查看订单详情</a-button>
          <a-button @click="goBackFun">返回继续购买</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderItemByOrderSn } from '@/api/order'
export default {
  name: 'PaySuccess',
  components: {
  },
  data() {
    return {
      orderItems: []
    }
  },
  mounted() {
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      const href = window.location.href
      const orderSn = href.split('?')[1].split('&')[1].split('=')[1]
      const data = {
        orderSn: orderSn
      }
      getOrderItemByOrderSn(data).then(res => {
        this.orderItems = res.data
      })
    },
    goBackFun() {
      this.$router.replace({ path: '/car' })
    },
    goOrderDetailsFun() {
      this.$router.replace({ path: '/order/orderDetails', query: { id: this.orderItems[0].orderId }})
    }
  }
}
</script>
<style lang='less' scoped>
.PaySuccess {
  padding: 64px 0 146px;

  .PaySuccess-title {
    margin-bottom: 24px;

    & > h3 {
      margin: 0;
      color: #333333;
      font-weight: bold;
      font-size: 36px;
      margin-bottom: 64px;
    }

    & > span {
      color: #333333;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .PaySuccess-details {
    width: 100%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    margin-bottom: 64px;

    .details-top {
      width: 100%;
      height: 69px;
      background-color: #F8F8F8;
      padding: 24px 72px;
      display: flex;
      align-items: center;

      & > span {
        font-size: 16px;
        color: #666666;
        font-weight: 400;
        line-height: 16px;
      }
      & > span:first-child {
        margin-right: 72px;
      }
       .details-num {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .details-bottom {
      width: 100%;
      height: 69px;
      background-color: #F8F8F8;
      padding: 11px 72px;
      text-align: right;

      & > button {
        width: 180px;
        height: 48px;
        background: #3B6652;
        border-radius: 32px;
        color: #fff;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }

    .details-center {
      width: 100%;
      min-height: 175px;
      padding: 24px 72px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .details-center-items {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .img-box {
        width: 88px;
        height: 88px;
        margin-right: 24px;

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .details-info {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > span {
          color: #666666;
          font-weight: 400;
          display: block;
          margin-bottom: 8px;
        }
      }

    }
  }
}
</style>
